<template>
  <div class="custom-input">
    <v-menu
      ref="timePickerMenu"
      v-model="pickerOpen"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      :disabled="isDisabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          type="text"
          outlined
          color="primary accent-4"
          :append-icon="icon.mdiClockTimeFourOutline"
          :label="label"
          :value="fieldValue"
          :dense="dense"
          :disabled="isDisabled"
          readonly
          :hide-details="hideDetails"
          :placeholder="placeholder"
          :class="inputClass"
          autocomplete="new-password"
          persistent-placeholder
          :rules="rules"
          v-bind="attrs"
          v-on="on"
          @click:clear="updateFieldValue('')"
          :clearable="!disabledClear"
        />
      </template>
      <v-time-picker
        scrollable
        v-model="time"
        full-width
        @input="updateFieldValue($event)"
        :readonly="isReadOnly"
        :min="min"
        :max="max"
      />
    </v-menu>
  </div>
</template>

<script>
import { mdiClockTimeFourOutline } from "@mdi/js";

export default {
  name: "FormTimePicker",
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    fieldValue: {
      type: String,
      required: true,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: true,
    },
    customInputClass: {
      type: String,
      required: false,
      default: "",
    },
    customRules: {
      type: Array,
      required: false,
      default: () => [],
    },
    dispatchUpdateOnChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledClear: {
      type: Boolean,
      required: false,
      default: false,
    },
    min: {
      type: String,
      required: false,
      default: null
    },
    max: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    rules() {
      return [
        ...this.customRules,
        (v) => { return this.required ? this.$validate.required(v) || "此位置不能留空" : true; },
      ];
    },
    isReadOnly() {
      return this.readonly || this.$store.getters.isLoading;
    },
    isDisabled() {
      return this.disabled || this.$store.getters.isLoading;
    },
    inputClass() {
      let classObj = {
        "pointer-none": this.$store.getters.isLoading || this.disabled || this.readonly,
      };
      if (this.$validate.DataValid(this.customInputClass)) {
        const arr = this.customInputClass.split(" ");
        arr.forEach((name) => {
          if (this.$validate.DataValid(name.trim())) {
            classObj[name.trim()] = true;
          }
        });
      }

      return classObj;
    },
  },
  data: () => ({
    icon: {
      mdiClockTimeFourOutline,
    },
    pickerOpen: false,
    time: ''
  }),
  watch: {
    fieldValue: {
      handler(val) {
        this.time = val
      },
    },
  },
  methods: {
    updateFieldValue(val) {
      this.$emit("update:fieldValue", val);
      this.$emit("changed", val);
      if (this.dispatchUpdateOnChange === true) {
        this.$store.dispatch("setDataIsUpdated", true);
      }
    },
  },
};
</script>
