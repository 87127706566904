<template>
    <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <div class="custom-input">
                <v-text-field
                    type="text"
                    outlined
                    color="primary accent-4"
                    :label="label"
                    :value="displayValue" 
                    :dense="dense"
                    :disabled="disabled" 
                    readonly
                    :hide-details="hideDetails"
                    :placeholder="placeholder" 
                    :prefix="prefix"
                    :suffix="suffix"
                    :class="inputClass"
                    autocomplete="new-password"
                    persistent-placeholder
                    :rules="rules.text" 
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </div>
        </template>

        <v-container fluid style="background: #ffffff;">
            <v-text-field 
                v-model="searchText" 
                clearable
                dense
                hide-details
                :rules="searchTextRules"
                :placeholder="searchPlaceholder" 
                @input="inputSearch($event)"
                @click:clear="clearSearch()"
            ></v-text-field>
            <v-divider class="my-2"></v-divider>
            
            <v-list style="max-height: 50vh; overflow-x: hidden; overflow-y: auto;">
                <v-list-item v-show="!options || options.length < 1">
                    <v-list-item-title>沒有資料</v-list-item-title>
                </v-list-item>

                <v-list-item v-for="(item, index) in options" :key="index" @click="onSearchResultClick(item.value)">
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-container>
       
    </v-menu>
   
</template>

<script>

export default {
    name: 'FormSearchSelect',
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        fieldValue: {
            type: String | Number,
            required: true,
            default: ''
        },
        fieldDisplayValue: {
            type: String,
            required: false,
            default: ''
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: true
        },
        prefix: {
            type: String,
            required: false,
            default: ''
        },
        suffix: {
            type: String,
            required: false,
            default: ''
        },
        customInputClass: {
            type: String,
            required: false,
            default: ''
        },
        dispatchUpdateOnChange: {
            type: Boolean,
            required: false,
            default: false
        },
        searchTextRules: {
            type: Array,
            default: () => []
        },
        searchPlaceholder: {
            type: String,
            required: false,
            default: ''
        },
        options: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
    computed: {
        rules() {
            return {
                text: [
                    v => {
                        return this.required ? (this.$validate.required(v) || '此位置不能留空') : true
                    },
                ],
            };
        },
        inputClass() {
            let classObj = {
                'pointer-none': this.$store.getters.isLoading,
            };
            if (this.$validate.DataValid(this.customInputClass)) {
                const arr = this.customInputClass.split(' ');
                arr.forEach(name => {
                    if (this.$validate.DataValid(name.trim())) {
                        classObj[name.trim()] = true;
                    }
                })
            }

            return classObj;
        },
        displayValue() {
            if (this.$validate.DataValid(this.fieldDisplayValue)) {
                return this.fieldDisplayValue;
            } else if (this.$validate.DataValid(this.fieldValue)) {
                const selected = this.options.find(el => el.value === this.fieldValue);
                if (selected) {
                    return selected.text;
                }
            }

            return '';
        }
    },
    data: () => ({
        searchText: '',
    }),
    methods: {
        inputSearch(value) {
            this.$emit('input-search', value);
        },

        clearSearch() {
            this.$emit('input-search', '');
        },

        onSearchResultClick(value) {
            const target = this.options.find(el => el.value === value);
            if (target) {
                this.$emit('update:fieldValue', value);
                this.$emit('update:fieldDisplayValue', target.text);
                if (this.dispatchUpdateOnChange === true) {
                    this.$store.dispatch('setDataIsUpdated', true);
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .pw-view .v-input__append-inner {
    margin: 0 !important;
    
}
</style>