<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading">
      <template #rightSlot>
        <div class="d-flex align-center" v-if="id !== null">
          <v-btn
            depressed
            color="primary accent-4"
            class="white--text mr-3"
            :to="{ name: 'UserDetail', params: { type: 'student', id: formData.student_id } }"
            >查看學生</v-btn
          >
          <v-btn
            depressed
            color="primary accent-4"
            class="white--text"
            v-if="!editLessonOnly"
            :to="{
              name: 'CreateOrder',
              params: { studentId: formData.student_id, jobId: id },
            }"
            >新增訂單</v-btn
          >
          <v-btn
            depressed
            color="primary accent-4"
            class="white--text"
            v-else
            :to="{
              name: 'OrderDetail',
              params: { id: orderId },
            }"
            >查看訂單</v-btn
          >
        </div>
      </template>
    </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">課堂資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <form-search-select
                label="學生"
                :fieldValue.sync="formData.student_id"
                :fieldDisplayValue.sync="selectedStudent"
                required
                :options="studentOptions"
                @input-search="searchStudent($event)"
                :disabled="id !== null"
                searchPlaceholder="搜尋學生姓名/電話"
              />
            </v-col>
            <v-col cols="12" md="6" v-if="id !== null">
              <form-select label="狀態" :fieldValue.sync="formData.status" :options="$statusOptions" required />
            </v-col>
            <v-col cols="12" md="6">
              <form-select
                label="課堂類型"
                :fieldValue.sync="formData.lesson_category"
                :options="$lessonCategoryOptions"
                required
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-select label="科目" :fieldValue.sync="formData.subject" :options="$subjectOptions" required />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">導師篩選</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <form-select
                label="導師學歷"
                :fieldValue.sync="formData.qualification"
                :options="$qualificationOptions"
                required
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-select label="導師性別" :fieldValue.sync="formData.gender" :options="$genderOptions" required />
            </v-col>
            <v-col cols="12" md="6">
              <form-select label="導師年齡層" :fieldValue.sync="formData.age" :options="$ageOptions" required />
            </v-col>
            <v-col cols="12" md="6">
              <form-select
                label="導師經驗"
                :fieldValue.sync="formData.experience"
                :options="$experienceOptions"
                required
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-search-select
                label="導師"
                :fieldValue.sync="formData.tutor_id"
                :fieldDisplayValue.sync="selectedTutor"
                required
                :options="tutorOptions"
                @input-search="searchTutor($event)"
                searchPlaceholder="搜尋導師姓名/電話"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-8">
        <v-card-text>
          <div class="d-flex align-center justify-space-between">
            <v-card-title class="page-title">課堂時間</v-card-title>
            <v-btn
              depressed
              color="primary accent-4"
              class="white--text mr-3"
              @click="openLessonModal()"
              v-if="!editLessonOnly"
              >新增</v-btn
            >
          </div>

          <datatable
            :tableHeaders="tableHeaders"
            :tableData="tableData"
            disablePagination
            disableFooter
            disableShadow
            @delete-clicked="deleteLesson($event)"
            @view-clicked="editLesson($event)"
          >
            <template #item.time="{ item }">{{ item.start_time }} - {{ item.end_time }}</template>
          </datatable>
        </v-card-text>
      </v-card>

      <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading">
        儲存
      </v-btn>
    </v-form>

    <Dialog :open="lessonOpen" :loading="$store.getters.isLoading" eager enableCancel @close="addLesson($event)">
      <template #content>
        <v-form ref="formAddLesson" @submit.prevent>
          <v-row class="ma-0">
            <v-col cols="12">
              <form-date-picker label="課堂日期" :fieldValue.sync="addLessonDate" required disabledActionButtons />
            </v-col>
            <v-col cols="12" md="6">
              <form-time-picker
                label="課堂開始時間"
                :fieldValue.sync="addLessonStartTime"
                required
                :max="addLessonEndTime"
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-time-picker
                label="課堂結束時間"
                :fieldValue.sync="addLessonEndTime"
                required
                :min="addLessonStartTime"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormSearchSelect from '@/components/form/FormSearchSelect.vue'
import Datatable from '@/components/Datatable.vue'
import Dialog from '@/components/layout/Dialog.vue'
import FormDatePicker from '@/components/form/FormDatePicker.vue'
import FormTimePicker from '@/components/form/FormTimePicker.vue'

export default {
  name: 'JobDetail',
  components: {
    FormInput,
    FormSelect,
    FormSearchSelect,
    Datatable,
    Dialog,
    FormDatePicker,
    FormTimePicker,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    tableHeaders() {
      return [
        { text: '課堂日期', value: 'date' },
        { text: '課堂時間 ', value: 'time' },
        { text: '', value: this.editLessonOnly ? 'actionView' : 'actionViewDelete', width: 230 },
      ]
    },
  },
  data: () => ({
    formData: {
      student_id: null,
      status: 'active',
      lesson_category: null,
      subject: null,
      qualification: null,
      gender: null,
      age: null,
      experience: null,
      tutor_id: null,
    },
    selectedStudent: '',
    selectedTutor: '',

    studentOptions: [],
    allStudents: [],

    tutorOptions: [],
    allTutors: [],

    tableData: [],
    tableCount: 0,
    lessonOpen: false,
    addLessonDate: '',
    addLessonStartTime: '',
    addLessonEndTime: '',
    editLessonId: null,
    initLessonCount: null,
    editLessonOnly: false,
    orderId: null,
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetJobById(this.id, user.id, user.token)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key]) && key !== 'subject') {
            this.$set(this.formData, key, data[key])
          }
        }

        if (data.subject && data.subject.length) {
          this.formData.subject = data.subject[0]
        }

        this.initLessonCount = data.course_timetable.length
        if (data.edit_timetable_only) {
          this.editLessonOnly = true
        }
        if (data.order_id !== undefined) {
          this.orderId = data.order_id
        }
        for (let i = 0; i < data.course_timetable.length; i++) {
          this.tableData.push({
            id: i,
            date: data.course_timetable[i].date,
            start_time: data.course_timetable[i].start_time,
            end_time: data.course_timetable[i].end_time,
          })
          this.tableCount += 1
        }
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'JobList',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'JobList',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async getStudentOptions() {
      try {
        const user = this.getCurrentUserData()
        const { data } = await this.$Fetcher.GetStudents({}, user.id, user.token)
        this.allStudents = data
        this.studentOptions = data.map(item => {
          return { value: item.id, text: item.name ? item.name : item.phone }
        })
      } catch {}
    },
    searchStudent(keyword) {
      let founds = this.allStudents
      if (keyword) {
        founds = this.allStudents.filter(
          item =>
            (item.name && item.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
            (item.phone && item.phone.indexOf(keyword) > -1),
        )
      }
      this.studentOptions = founds.map(item => {
        return { value: item.id, text: item.name ? item.name : item.phone }
      })
    },

    async getTutorOptions() {
      try {
        const user = this.getCurrentUserData()
        const { data } = await this.$Fetcher.GetTutors({}, user.id, user.token)
        this.allTutors = data
        this.tutorOptions = data.map(item => {
          return { value: item.id, text: item.name ? item.name : item.phone }
        })
      } catch {}
    },
    searchTutor(keyword) {
      let founds = this.allTutors
      if (keyword) {
        founds = this.allTutors.filter(
          item =>
            (item.name && item.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
            (item.phone && item.phone.indexOf(keyword) > -1),
        )
      }

      this.tutorOptions = founds.map(item => {
        return { value: item.id, text: item.name ? item.name : item.phone }
      })
    },

    async openLessonModal() {
      if (this.$store.getters.isLoading) {
        return
      }
      this.addLessonDate = ''

      if (this.tableData.length) {
        this.addLessonStartTime = this.tableData[this.tableData.length - 1].start_time
        this.addLessonEndTime = this.tableData[this.tableData.length - 1].end_time
      } else {
        this.addLessonStartTime = ''
        this.addLessonEndTime = ''
      }

      this.lessonOpen = true
    },

    async addLesson(action) {
      if (action) {
        if (this.$store.getters.isLoading) {
          this.$store.dispatch('toggleSnack', {
            message: 'processing',
          })
          return
        }

        const valid = await this.$refs['formAddLesson'].validate()
        if (!valid) {
          this.$store.dispatch('toggleSnack', {
            message: 'required',
          })
          return
        }

        const repeat = this.tableData.find(el => {
          if (el.date === this.addLessonDate && this.editLessonId !== el.id) {
            const start_time = new Date(`${el.date}T${el.start_time}:00Z`)
            const end_time = new Date(`${el.date}T${el.end_time}:00Z`)
            const add_start_time = new Date(`${this.addLessonDate}T${this.addLessonStartTime}:00Z`)
            const add_end_time = new Date(`${this.addLessonDate}T${this.addLessonEndTime}:00Z`)

            if (
              (add_start_time.getTime() >= start_time.getTime() && add_start_time.getTime() <= end_time.getTime()) ||
              (add_end_time.getTime() >= start_time.getTime() && add_end_time.getTime() <= end_time.getTime())
            ) {
              return true
            }
          }

          return false
        })

        if (repeat) {
          this.$store.dispatch('toggleSnack', {
            type: 'error',
            message: '出現重疊課堂',
          })

          return
        }

        if (this.editLessonId !== null) {
          const found = this.tableData.find(el => el.id === this.editLessonId)
          if (found) {
            found.date = this.addLessonDate
            found.start_time = this.addLessonStartTime
            found.end_time = this.addLessonEndTime
          }
        } else {
          if (this.editLessonOnly && this.initLessonCount !== null && this.tableData.length === this.initLessonCount) {
            this.$store.dispatch('toggleSnack', {
              type: 'error',
              message: '現有課堂數量需與原有課堂數量相同',
            })
            return
          }

          this.tableData.push({
            id: this.tableCount,
            date: this.addLessonDate,
            start_time: this.addLessonStartTime,
            end_time: this.addLessonEndTime,
          })
          this.tableCount += 1
        }

        this.tableData.sort((a, b) => {
          const a_date = new Date(`${a.date}T${a.start_time}:00Z`)
          const b_date = new Date(`${b.date}T${b.start_time}:00Z`)
          return a_date.getTime() - b_date.getTime()
        })
      }

      this.editLessonId = null
      this.lessonOpen = false
    },

    deleteLesson(id) {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }
      this.tableData = this.tableData.filter(el => el.id !== id)
    },

    editLesson(id) {
      if (this.$store.getters.isLoading) {
        return
      }
      const found = this.tableData.find(el => el.id === id)
      if (found) {
        this.addLessonDate = found.date
        this.addLessonStartTime = found.start_time
        this.addLessonEndTime = found.end_time
        this.editLessonId = id
        this.lessonOpen = true
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }

      if (this.tableData.length < 1) {
        this.$store.dispatch('toggleSnack', {
          type: 'error',
          message: '至少新增一個課堂',
        })
        return
      }

      if (this.editLessonOnly && this.initLessonCount !== null && this.tableData.length !== this.initLessonCount) {
        this.$store.dispatch('toggleSnack', {
          type: 'error',
          message: '現有課堂數量需與原有課堂數量相同',
        })
        return
      }

      this.$store.dispatch('setLoading', true)
      
      let payload = {
        student_id: this.formData.student_id,
        lesson_category: this.formData.lesson_category,
        subject: this.formData.subject ? [this.formData.subject] : [],
        qualification: this.formData.qualification,
        gender: this.formData.gender,
        age: this.formData.age,
        experience: this.formData.experience,
        tutor_id: this.formData.tutor_id,
        course_timetable: this.tableData.map(el => {
          return {
            date: el.date,
            start_time: el.start_time,
            end_time: el.end_time,
          }
        }),
      }

      const user = this.getCurrentUserData()

      if (this.$validate.DataValid(this.id)) {
        payload['status'] = this.formData.status
        try {
          await this.$Fetcher.UpdateJob(this.id, payload, user.id, user.token)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '儲存成功',
            refresh: true,
          })
        } catch (err) {
          this.setAlert({
            title: '儲存失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        try {
          await this.$Fetcher.NewJob(payload, user.id, user.token)

          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '新增成功',
            redirect: 'JobList',
          })
        } catch (err) {
          this.$common.error(err)
          this.setAlert({
            title: '新增失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    await this.getStudentOptions()
    await this.getTutorOptions()
    if (this.$validate.DataValid(this.id)) {
      this.getDetail()
    } else {
      this.$store.dispatch('setInitLoading', false)
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
